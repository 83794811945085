import { Link } from "gatsby";
import React from "react";

const Footer = () => (
  <div className="container footer">
    
  </div>
);

export default Footer;
