import React, { useState } from "react";

import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";

const Nav = ({ menuItems }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <div className="fixed top-0 left-0 z-20 ">
        <ul>
          <Scrollspy
            items={["about", "works", "services", "contact"]}
            currentClassName="text-white"
            className="bg-pink py-3 w-full flex-row-reverse justify-around font-mono hidden md:flex"
            style={{
              width: "100vh",
              transformOrigin: "right top",
              transform: "rotate(270deg) translateY(-100vh)",
            }}
          >
            {menuItems !== undefined &&
              menuItems.map((menuItem) => (
                <li className="inline-block mx-2">
                  <AnchorLink
                    href={`#${menuItem.anchor}`}
                    className="hover:text-white lowercase"
                  >
                    {menuItem.name}
                  </AnchorLink>
                </li>
              ))}
          </Scrollspy>
        </ul>
      </div>
      <div className="fixed bottom-0 right-0 z-50 flex flex-col md:hidden">
        <ul
          className="font-mono text-right"
          style={{ display: showMenu === true ? "block" : "none" }}
        >
          {menuItems !== undefined &&
            menuItems.map((menuItem) => (
              <li className="block py-2 mr-2">
                <AnchorLink
                  href={`#${menuItem.anchor}`}
                  className="px-2 py-1 bg-pink text-sm text-white lowercase"
                  onClick={() => setShowMenu(!showMenu)}
                >
                  {menuItem.name}
                </AnchorLink>
              </li>
            ))}
        </ul>
        <div className="flex justify-end mr-2 mb-2 mt-3">
          <button
            className={`hamburger hamburger--squeeze bg-pink ${
              showMenu === true && `is-active`
            }`}
            style={{ paddingBottom: "11px" }}
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
            onClick={() => {
              setShowMenu(showMenu === true ? false : true);
            }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Nav;
