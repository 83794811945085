import Footer from "../components/footer";
import NavBar from "../components/navBar";
import React from "react";

const Layout = ({ children, menuItems }) => {
  return (
    <div className="overflow-x-hidden">
      <NavBar menuItems={menuItems} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
